import { type EventMap, Events, PRESET, type Props } from "@/app/api"
import Styles from "./styles.css?inline"
import { ShadowRealm, Vite, attrs2props } from "./utils"

const liqa = Vite.dependencies(() => import("@/app"))
const errors = Vite.dependencies(() => import("@/libs/telemetry/errors"))

/**
 * The Web component name.
 * @category Misc
 */
export const NAME = "hautai-liqa" as const

/**
 * LIQA Web component HTML attributes to configure the component look and behavior.
 * @example
 * ```html
 * <hautai-liqa
 *   license="xxx-xxx-xxx"
 *   styles="@import url('/path/to/custom-liqa-styles.css')"
 * >
 * </hautai-liqa>
 * ```
 * @category Component
 */
export type LiqaAttributes = {
  /** The license key obtained from {@link https://haut.ai/#contacts Haut.ai }. */
  license: string
  /**
   * A string containing valid CSS rules. For more information, see [Styles](/customization/ui/styles) page.
   * @example
   * "@import url('/path/to/custom-liqa-styles.css')"
   */
  styles?: string
  /**
   * A string containing a valid JSON object whose keys are messages keys and values are messages texts. For more information, see [Text Messages](/customization/ui/text-messages) page.
   * @example
   * "{ \"preview\": { \"submit\": \"Confirm\" } }"
   */
  messages?: string
  /**
   * A preset used to customize AI quality criteria and user flow. For more information, see [Presets](customization/user-flow/presets) page.
   * @default "face"
   */
  preset?: "face" | "face-180" | "hair"
  /**
   * A list of sources to capture the image from: different device cameras or file upload. They can be combined using `,` separator.
   * Visit the corresponding [user flow customization](/customization/user-flow/image-sources) section to learn about their logic and limitations.
   *
   * @default "front_camera"
   * @alias `"camera"`
   * Starting from `6.7.0` version `"camera"` acts as an alias for `"front_camera"`.
   *
   * _Note: For_ `face-180` _preset only_ `"front_camera"` _source is available. The rest will be ignored._
   */
  sources?:
    | "front_camera"
    | "back_camera"
    | "upload"
    | "companion"
    | "front_camera,back_camera"
    | "front_camera,upload"
    | "front_camera,upload,companion"
    | "front_camera,companion"
    | "front_camera,back_camera,upload"
    | "front_camera,back_camera,companion"
    | "front_camera,back_camera,upload,companion"
    | "back_camera,upload"
    | "back_camera,companion"
    | "back_camera,upload,companion"
    | "upload,companion"
  /**
   * Image capture mode for `"camera"` source.
   * `"auto"` means LIQA will automatically take a photo when all AI quality criteria met.
   * `"manual"` means LIQA will allow the user to take a photo on their own via a button.
   *
   * _Note: The option is only applicable to_ `"hair"` _preset. For other presets it is always_ `"auto"`_._
   */
  capture?: "auto" | "manual"
  /**
   * Lighting requirements for the image capture.
   * - `"none"`: no requirements are set and lighting will not be validated. (**WARNING**: the quality of the processing results may be affected)
   * - `"adaptive"`: the requirements will be softened if the lighting conditions are poor.
   * - `"default"`: the requirements will be set to optimal which will provide more details on the photo.
   *
   * _Note: The option is only applicable to_ `"face"` _preset. For other presets it is always_ `"default"`_._
   *
   * @default `"default"`
   */
  "required-lighting"?: "none" | "adaptive" | "default"
  /**
   * A flag to enable or disable the effects during the image capture process.
   *
   * @default false
   */
  effects?: boolean | "true" | "false"
}

/**
 * The component event names and corresponding event details.
 * @example
 * ```html
 * <hautai-liqa
 *   license="xxx-xxx-xxx"
 *   onready="console.log('LIQA is ready!')"
 *   onerror="console.warn('An error occurred:', event.detail)"
 * >
 * </hautai-liqa>
 * ```
 * @category Component
 */
export type {
  AnalyticsDetails,
  AnalyticsEventsMap,
  CaptureFormat,
  CaptureMetadata,
  CaptureTransformations,
  ErrorCodes,
  ImageCapture,
  LiqaError,
} from "@/app"
export type { EventMap as LiqaEventMap }

// TODO: document all the black magic going here and particularly why it‘s implemented as it‘s implemented

/**
 * LIQA Web component available as `<hautai-liqa></hautai-liqa>` tag.
 * @example
 * ```html
 * <hautai-liqa license="xxx-xxx-xxx"></hautai-liqa>
 * ```
 * @category Component
 */
export class LiqaElement extends HTMLElement {
  private readonly __root__: ShadowRoot
  private __widget__: Promise<{
    component: ReturnType<typeof import("@/app").create>
    globalErrorTracker: { destroy: () => void }
  }> | null

  /** @hidden */
  constructor() {
    super()

    const mode = typeof __LIQA_E2E__ !== "undefined" && __LIQA_E2E__ ? "open" : "closed"

    this.__root__ = this.attachShadow({ mode })
    this.__widget__ = null

    // new CSSStyleSheet() API is not available on Safari < 16.4
    const style = document.createElement("style")
    style.innerHTML = Styles
    this.__root__.appendChild(style)
  }

  protected connectedCallback() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_components/Using_custom_elements#using_the_lifecycle_callbacks
    if (!this.isConnected) return

    if (this.__widget__) return

    const root = this.__root__

    // Isolated JavaScript execution context
    const realm = new ShadowRealm(root)

    if (typeof __LIQA_E2E__ !== "undefined" && __LIQA_E2E__) {
      realm["_getGlobal"]().then((realm) => {
        // use global getUserMedia instead of the isolated one from the Realm to allow test environment to mock it
        Object.getPrototypeOf(realm.navigator.mediaDevices).getUserMedia = Object.getPrototypeOf(
          self.navigator.mediaDevices,
        ).getUserMedia
      })
    }

    this.__widget__ = Promise.resolve()
      .then(() =>
        realm
          // enable global error tracking
          .importValue<typeof import("@/libs/telemetry/errors").setup>(
            Vite.resolve(errors.js),
            "setup",
          )
          .then((setup) => setup())
          .then((destroy) => ({ destroy })),
      )
      .then((globalErrorTracker) => {
        // Inject the dynamically imported by Vite CSS styles into the shadowRoot instead of the Realm‘s document.head
        // https://github.com/vitejs/vite/issues/11855#issuecomment-1762258886
        realm["_getGlobal"]().then((realm) => Vite.adoptStyles(realm.document.head, root))

        return Vite.preload(
          () => realm.importValue<typeof import("@/app").create>(Vite.resolve(liqa.js), "create"),
          liqa.css,
          import.meta.url,
          root,
        ).then((create) => {
          const attrs = attrs2props<LiqaAttributes>(this.attributes)
          const props: Props = {
            ...attrs,
            preset: attrs.preset as PRESET,
            effects: !!attrs.effects && String(attrs.effects) !== "false",
            settings: (attrs as any).settings ? JSON.parse((attrs as any).settings) : undefined,
            messages: attrs.messages ? JSON.parse(attrs.messages) : undefined,
            sources: attrs.sources
              ? (attrs.sources?.split(",").map((s) => s.trim()) as Props["sources"])
              : undefined,
          }

          const component = create(root, props)

          return { component, globalErrorTracker }
        })
      })
      .catch((error) => {
        // track any errors during the component loading and initialization
        const license = this.attributes.getNamedItem("license")?.value

        realm
          .importValue(Vite.resolve(error.js), "log")
          .then((log) => log(error, { tags: { license } }))

        throw error
      })
  }

  protected disconnectedCallback() {
    if (!this.__widget__) return

    const root = this.__root__
    const children = new Set(root.children)

    this.__widget__.then(({ component, globalErrorTracker }) => {
      globalErrorTracker.destroy()
      component?.destroy()

      for (const child of root.children) if (children.has(child)) root.removeChild(child)
    })
    this.__widget__ = null
  }

  /** @internal */
  override addEventListener(...args: unknown[]): void {
    if (args[0] === Events.LOADED) {
      args[0] = Events.READY
      console.warn(
        `[LIQA] Deprecation warning: the "${Events.LOADED}" event has been deprecated. Subscribe to "${Events.READY}" event instead.`,
      )
    } else if (args[0] === Events.CAPTURE) {
      console.warn(
        `[LIQA] Deprecation warning: the "${Events.CAPTURE}" event has been deprecated. Subscribe to "${Events.CAPTURES}" event instead.`,
      )
    }

    super.addEventListener(...(args as [string, EventListenerOrEventListenerObject]))
  }
}

export interface LiqaElement {
  /** Subscribes the event listener to the given LIQA {@link EventMap event}. */
  addEventListener<K extends keyof EventMap>(
    type: K,
    listener: (this: LiqaElement, ev: CustomEvent<EventMap[K]>) => any,
    options?: boolean | AddEventListenerOptions,
  ): void
  /** @hidden */
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions,
  ): void
  /** Unsubscribes the event listener from the given LIQA {@link EventMap event}. */
  removeEventListener<K extends keyof EventMap>(
    type: K,
    listener: (this: LiqaElement, ev: CustomEvent<EventMap[K]>) => any,
    options?: boolean | EventListenerOptions,
  ): void
  /** @hidden */
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions,
  ): void
}
